import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {ConfirmDialogComponent } from './confirmDialog.component';


@NgModule({
  imports: [
    ThemeModule,
  ],
  exports: [ConfirmDialogComponent],
  declarations: [
    ConfirmDialogComponent
  ],
})
export class ConfirmDialogModule { }
