import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import { UserService } from '@app/@core/data/user/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { group } from '@angular/animations';
@Component({
  selector: 'ngx-user-dialog',
  styleUrls: ['./userDialog.component.scss'],
  templateUrl: './userDialog.component.html',
})

export class UserDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  
    @Input() data;
    newGroups:Array<any>=[];
    loadingSpinnerMessage=this.translateService.instant('global-tags.please-wait');
    loadingSpinner:boolean=true;
    userLocations:Array<string>=[];
    userGroups:Array<string>=[];
    prevUserGroups:Array<string>=[];
    roles:Array<any>=[
        {
            id:3,
            name:this.translateService.instant('userDialog.admin')
        },
        {
            id:4,
            name:this.translateService.instant('userDialog.user')
        }
    ];
    actives:Array<any>=[
        {
            id:0,
            name:this.translateService.instant('userDialog.inactive')
        },
        {
            id:1,
            name:this.translateService.instant('userDialog.active')
        }
    ];
    showDetails:boolean=false;
    locations:Array<any>=[];
    groups:Array<any>=[];
    response:any={'data':[]}
    constructor(
    private apiService: ApiService,
    private userService: UserService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<UserDialogComponent>
    ) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translateService.use(event.lang);
          });
     }

    changeGroup(event)
    {
        if(event.includes("-1") || event.includes(-1))
        {
            if(event.length>1)
            {
                this.userLocations=[];
            }
            else
            {
                this.userLocations=[];
                for(var k=0;k<this.newGroups.length;k++)
                {
                    for(var j=0;j<this.newGroups[k].children.length;j++)
                    {
                        this.userLocations.push(this.newGroups[k].children[j].id);
                    }
                }
            }
        }
    }

    selectCurentGroup(event,groupId)
    {
        var target = event.target || event.srcElement || event.currentTarget;
        target=target.className;
        if(target=="option-group-title")
        {
            var curGroup;
            var k=-1;
            for(var i = 0;i<this.newGroups.length;i++)
            {
                if(this.newGroups[i].groupId==groupId)
                {
                    curGroup=this.newGroups[i];
                    k=i;
                    break;
                }
            }
            var idsToRemove=[];
            for(var i=0;i<this.userLocations.length;i++)
            {
                for(var j=0;j<curGroup.children.length;j++)
                {
                    if(curGroup.children[j].id==this.userLocations[i])
                    {
                        idsToRemove.push(curGroup.children[j].id);
                    }
                }
            }
            var tmpSelectedGroups=this.userLocations;
            if(idsToRemove.length==0)
            {
                this.userLocations=[];
                for(var j=0;j<curGroup.children.length;j++)
                {
                    this.userLocations.push(this.newGroups[k].children[j].id);
                }
                for(var i=0;i<tmpSelectedGroups.length;i++)
                {
                    this.userLocations.push(tmpSelectedGroups[i]);
                }
            }
            else
            {
                tmpSelectedGroups= tmpSelectedGroups.filter((el) => !idsToRemove.includes( el ));
                this.userLocations=[];
                for(var i=0;i<tmpSelectedGroups.length;i++)
                {
                    this.userLocations.push(tmpSelectedGroups[i]);
                }
            }
        }
    }

    ngOnInit() {
     this.apiService.GetLocationsWithDevicesList().subscribe(res=>{
        var locations=res.data;
        this.apiService.GetMyLocationGroups().subscribe(res=>{
            this.groups=res.data;
            var groups=res.data;
            for(var i=0;i<groups.length;i++)
            {
                var tmp={
                    name:groups[i].name,
                    groupId:groups[i].groupId,
                    children:[]
                };
                for(var j=0;j<groups[i].locations.length;j++)
                {
                    for(var l = 0;l<locations.length;l++)
                    {
                        if(locations[l].locationId == groups[i].locations[j].locationId)
                        {
                            var tmpLoc={
                                name:locations[l].name +"("+locations[l].devices[0].klNumber+")",
                                id:this.generateUUID(),
                                locationId:locations[l].locationId
                            }
                            tmp['children'].push(tmpLoc);
                            break;
                        }
                    }
                }
                this.newGroups.push(tmp);
            }
            tmp={
                name:"Other",
                groupId:-2,
                children:[]
            };
            for(var l = 0;l<locations.length;l++)
            {
                var fl=false;
                for(var i = 0;i<this.newGroups.length;i++)
                {
                    for(var k = 0;k<this.newGroups[i].children.length;k++)
                    {
                        if(locations[l].locationId==this.newGroups[i].children[k].locationId)
                        {
                            fl=true;
                            break;
                        }
                    }
                }
                if(!fl)
                {
                    tmpLoc={
                        id:this.generateUUID(),
                        name:locations[l].name +"("+locations[l].devices[0].klNumber+")",
                        locationId:locations[l].locationId
                    }
                    tmp['children'].push(tmpLoc);
                }
            }
            if(tmp.children.length>0)
            {
                this.newGroups.push(tmp);
            }
            this.apiService.GetLocationsListByUser(this.data.userId).subscribe(res=>{
                var locationsUser=res.data;
                
                for(var l=0;l<locationsUser.length;l++)
                {
                    for(var i = 0;i<this.newGroups.length;i++)
                    {
                        for(var k = 0;k<this.newGroups[i].children.length;k++)
                        {
                            if(this.newGroups[i].children[k].locationId==locationsUser[l].locationId)
                            {
                                this.userLocations.push(this.newGroups[i].children[k].id);
                            }
                        }   
                    }
                    
                }
            });
            this.apiService.GetGroupsListByUser(this.data.userId).subscribe(res=>{
            this.showDetails=true;
            this.loadingSpinner=false;
            });
        });
     });
    }

    generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c=='x' ? r : (r&0x3|0x8)).toString(16);
        });
        return uuid;
      }

    closeDialog(){
      this.dialogRef.close();
    }

    saveDialog(){
        this.response.data = this.data;
        var usr={
            firstName:this.data.firstName,
            lastName:this.data.lastName,
            phone:this.data.phone,
            email:this.data.email,
            isActive:parseInt(this.data.isActiveBoolean),
            roles:[parseInt(this.data.roleId)]
        };
        var usrLcs=[];
        for(var i=0;i<this.userLocations.length;i++)
        {
            for(var k=0;k<this.newGroups.length;k++)
            {
                for(var j=0;j<this.newGroups[k].children.length;j++)
                {
                    if(this.userLocations[i]===this.newGroups[k].children[j].id)
                    {
                        if(!usrLcs.includes(this.newGroups[k].children[j].locationId))
                        {
                            usrLcs.push(this.newGroups[k].children[j].locationId);
                        }
                    }
                }
            }
        }
        var userGroups=[];
        for(var k=0;k<this.newGroups.length;k++)
        {
            var count=0;
            for(var j=0;j<this.newGroups[k].children.length;j++)
            {
                for(var i=0;i<this.userLocations.length;i++)
                {
                    if(this.userLocations[i]===this.newGroups[k].children[j].id)
                    {
                        count++;
                    }
                }
            }
            if(this.newGroups[k].children.length==count && this.newGroups[k].groupId!=-2)
            {
                userGroups.push(this.newGroups[k].groupId);
            }
        }
        var locs={'locations':usrLcs};
        for(var g=0;g<this.groups.length;g++)
        {
            this.apiService.GetGroup(this.groups[g].groupId).subscribe(res=>{
            var group=res.data;
            var fl=false;
                for(var uG=0;uG<userGroups.length;uG++)
                {
                    if(userGroups[uG]==group.groupId)
                    {
                        fl=true;
                    }
                }
                var fu=-1;
                for(var u=0;u<group.users.length;u++)
                {
                    if(this.data.userId==group.users[u].userId)
                    {
                        fu=u;
                        break;
                    }
                }
                var usrIds=[];
                var putGroup=false;
                if(fl)
                {
                    if(fu==-1)
                    {
                        for(var u=0;u<group.users.length;u++)
                        {
                            usrIds.push(group.users[u].userId);
                        }
                        usrIds.push(this.data.userId);
                        putGroup=true;
                    }
                }
                else
                {
                    if(fu>-1)
                    {
                        putGroup=true;
                        group.users.splice(fu,1);
                        for(var u=0;u<group.users.length;u++)
                        {
                            usrIds.push(group.users[u].userId);
                        }
                    }
                }
                if(putGroup)
                {
                    var payload={
                        users:usrIds
                    };
                    this.apiService.UpdateLocationGroup(payload,group.groupId).subscribe(res=>{});
                }
            });
        }

        this.apiService.UpdateUserLocations(locs,this.data.userId).subscribe(res=>{
            this.userService.updateAdmin(usr,this.data.userId).subscribe(res=>{
                setTimeout(() => {
                    this.dialogRef.close(this.response);
                  }, 200);
            });
        });
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}
}
