import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {TicketDialogComponent } from './ticketDialog.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';


@NgModule({
  imports: [
    ThemeModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
   
  ],
  exports: [TicketDialogComponent],
  declarations: [
    TicketDialogComponent
  ],
})
export class TicketDialogModule { }
