import { EventEmitter, Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BroadcastingService {

  /* -------------------------
   * Broadcasting Service
   * -------------------------
   * A service to broadcast events to components/services about state changes 
   * without the need to   wait for the component to ask for the new state. 
   * Supports 3 techniques of broadcasting service notification:
   * 
   * EventEmitter
   * Subject
   * BehaviorSubject
   * 
   * # WorkFlow
   * 
   * A component calls a method in the service, notifying it of the change. 
   * The service then broadcasts an event.
   * Any component/service can listen for that event and respond accordingly.
   * 
   * # EventEmitter
   * 
   * Best practices discourage the use of EventEmmiter except with an output 
   * property (eg. when we use to send an event outside from child component to 
   * parent component).
   * 
   * # Subject
   * 
   * Subject is a special type of observable that can multicast a value or event 
   * to multiple subscribers. Any component/service that subscribes to the 
   * subject’s observable will receive notifications. Subject is also an observer, 
   * an observer allows us to push new data into an observable sequence. Any 
   * component or other service can feed new values into the subject using its 
   * next method. The new value then gets multicast to all subscribers.
   * 
   * # BehaviorSubject
   * 
   * The behaviorSubject works like a subject except of 2 key features: requires an 
   * initial value and provides the current value on a new subscription. This means 
   * that when the component is destroyed and reinitialized, a new subscription is 
   * created and since the subscription is for the behaviorSubject, the component 
   * will receive the last value from the behaviorSubject’s broadcast. Basically it 
   * means that the component/service will remember the value.
   * 
   * # Usage
   * 
   * < emitter.component.ts >
   * 
   *  this.broadcastingService.eventEmitter.emit(...);
   *   
   *  this.broadcastingService.changeSubject(...);
   * 
   *  this.broadcastingService.changeBehaviorSubject(...);
   * 
   * < receiver.component.ts >
   * 
   *  this.broadcastingService.eventEmitter.subscribe((msg: any) => {
   *    console.log(msg)
   *  });
   * 
   *  this.broadcastingService.subjectChanges$.subscribe((msg: any) => {
   *    console.log(msg)
   *  });
   *  
   *  this.broadcastingService.behaviorSubjectChanges$.subscribe((msg: any) => {
   *    console.log(msg)
   *  });
  
  */

  constructor() { }

  // Event Emmiter
  eventEmitter = new EventEmitter<any>();

  // Subject Observable
  private subjectSource = new Subject<any>();
  subjectChanges$ = this.subjectSource.asObservable();

  changeSubject(subject: any) {
    this.subjectSource.next(subject);
  }

  // behaviorSubject Observable
  private behaviorSubjectSource = new BehaviorSubject<any>(null);
  behaviorSubjectChanges$ = this.behaviorSubjectSource.asObservable();

  changeBehaviorSubject(behaviorSubject: any) {
    this.behaviorSubjectSource.next(behaviorSubject);
  }
}