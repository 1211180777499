import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DefaultEditor } from 'ng2-smart-table';
import { ApiService } from '@app/@core/data';
import { ElevatorStatusCardComponent } from '@app/components/elevator-status-card/elevator-status-card.component';
@Component({
  selector: 'app-checkbox',
  template: `<span style="color:red;" *ngIf="rowData.type=='1'">
      <nb-icon icon="radio-button-on" class="icon"></nb-icon>
    </span>
    <span style="color:orange;" *ngIf="rowData.type=='2'">
      <nb-icon icon="radio-button-on" class="icon"></nb-icon>
    </span> 
    <span style="color:blue;" *ngIf="rowData.type=='3'">
      <nb-icon icon="radio-button-on" class="icon"></nb-icon>
    </span> 
    <span style="color:green;" *ngIf="rowData.type=='4'">
      <nb-icon icon="radio-button-on" class="icon"></nb-icon>
    </span>`
})
export class CustomTableMaintenanceTypeComponent extends DefaultEditor implements OnInit {
  @Input() value: any;
  @Input() rowData: any;
  constructor(private apiService: ApiService) {
    super();
  }
  
  ngOnInit() {
  }

}