export enum Role {
  System = 'system',
  Admin = 'admin',
  User = 'user',
  Guest = 'guest',
  Device = 'device',
  Support = 'support',
  Fake = 'fake',
  Quality = 'quality'
}
