import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '@app/@core/data';
import { NbDialogRef } from '@nebular/theme';
import { GridsterItem } from 'angular-gridster2';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'ngx-new-dashboard-dialog',
  templateUrl: './new-dashboard-dialog.component.html',
  styleUrls: ['./new-dashboard-dialog.component.scss']
})
export class NewDashboardDialogComponent implements OnInit {

  @Input() data: Array<GridsterItem>;
  @Input() category: string;
  @Input() current_elevator: string;
  devices: any[] = [];
  constructor(private dialogRef: NbDialogRef<NewDashboardDialogComponent>, private apiService: ApiService) { }

  ngOnInit() {
    this.getElevators();
  }

  
  getElevators()
  {
    this.devices = [];
    this.apiService.GetLocationsWithDevicesList().subscribe(res =>{
      res.data.forEach(position => {
        position.devices.forEach(elevator => {
          if(elevator.hardwareId != this.current_elevator) {
            this.devices.push({id: elevator.hardwareId, name: position.name, selected: false});
          }
        });
      });
    });
  }

  checkWidget(elevator:any)
  {
    this.devices.forEach(device => {
      if(device.id == elevator.id && !device.selected) {
        device.selected = true;
      }
      else if(device.id == elevator.id && device.selected) {
        device.selected = false;
      }
    });
  }

  closeDialog(message:boolean){

    this.dialogRef.close(message);
  }

  saveDialog()  {
    const myPromise = new Promise<string[]>((resolve, reject)=> {
      var duplicate_devices = [];
      var myDevices = this.devices.filter(device => device.selected == true);
      myDevices.forEach(device => {
        duplicate_devices.push(device.id);
      });
      setTimeout(() => {
        resolve(duplicate_devices);
      }, 0);
    })
    myPromise.then(duplicate_devices => {
      this.submitWidgets(duplicate_devices);
    });
  }

  submitWidgets(duplicate_devices: string[]) {
    duplicate_devices.forEach(elevator => {
      this.apiService.GetDashboardByView(elevator, this.category).subscribe(res =>{
        if(res.data != null) {
          this.writeWidgets(res, 1);
        }
        else {
          var payload = {
            name: this.category,
            hardwareId: elevator
        }
          this.apiService.PostDashboard(payload).subscribe(res => {
            if(res.data) {
              this.writeWidgets(res, 0);
            }
          });
      }
    })
  });
  }
  
  writeWidgets(res: any,index: number) {
    const array: Observable<any>[] = [];
    if(index == 1) {
      for(var i=0; i<res.data.widgets.length; i++) {
        array.push(this.apiService.DeleteWidget(res.data.widgets[i].widgetId));
      }
    }
  
    if(array.length) {
      forkJoin(array).subscribe(() => {
        const items: Observable<any>[] = [];
        for(var j=0; j<this.data.length; j++) {
          var content = {
            x: this.data[j].x,
            y: this.data[j].y,
            cols: this.data[j].cols,
            rows: this.data[j].rows
          }
          var payloadWidgets = {
            content: JSON.stringify(content),
            dashboardId: res.data.dashboardId,
            type: this.data[j].type
          }
          items.push(this.apiService.PostWidgets(payloadWidgets));
        }
  
        if(items.length != 0) {
          forkJoin(items).subscribe(() => {
            this.closeDialog(true);
          });
        }
        else {
          this.closeDialog(true);
        }
      });
    }
    else {
      const items: Observable<any>[] = [];
      for(var j=0; j<this.data.length; j++) {
        var content = {
          x: this.data[j].x,
          y: this.data[j].y,
          cols: this.data[j].cols,
          rows: this.data[j].rows
        }
        var payloadWidgets = {
          content: JSON.stringify(content),
          dashboardId: res.data.dashboardId,
          type: this.data[j].type
        }
        items.push(this.apiService.PostWidgets(payloadWidgets));
      }
      if(this.data.length) {
        forkJoin(items).subscribe(() => {
          this.closeDialog(true);
        });
      }
      else {
        this.closeDialog(true);
      }
    }
  }

}
