import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {AlertDialogComponent } from './alertDialog.component';


@NgModule({
  imports: [
    ThemeModule,
  ],
  exports: [AlertDialogComponent],
  declarations: [
    AlertDialogComponent
  ],
})
export class AlertDialogModule { }
