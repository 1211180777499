import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import * as errorExplanation from '../../../assets/files/errorExplanation.json';
import * as errorTerminalSchema from '../../../assets/files/errorTerminalSchema.json';
import * as errorExplanationExtended from '../../../assets/files/errorExplanationExtended.json';
import { BroadcastingService } from "@app/@core/data/shared/broadcasting.service";

import { type } from 'jquery';
import { throwIfAlreadyLoaded } from '@app/@core/module-import-guard';

@Component({
  selector: 'ngx-error-details-dialog',
  styleUrls: ['./errorDetailsDialog.component.scss'],
  templateUrl: './errorDetailsDialog.component.html',
})
 
export class ErrorDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data;
  @Input() eventDate;
  elevatorId: string;
  notificationId: number;
  title: string;
  errorCode: String;
  errorMessage: String;
  floorArray: any = [];
  //eventDate: string;
  loadingSpinnerMessage = this.translateService.instant('global-tags.please-wait');
  loadingSpinner: boolean = true;
  type: string;
  read: string;
  terminals: any = [];
  terminalDataDetails: any = {};
  tableDataDetails: Array<any> = [];
  showTableDetails: boolean = false;
  elevatorName: string = "";
  elevatorKl: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private broadcastingService: BroadcastingService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<ErrorDetailsDialogComponent>
  ) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateService.use(event.lang);
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.id) {
        this.elevatorId = params.id;
      }
      else {
        this.elevatorId = this.data.hardwareId;
      }

      this.apiService.GetLocationsWithDevicesList().subscribe(res => {
        for (var i = 0; i < res.data.length; i++) {
          if (this.elevatorId == res.data[i].devices[0].hardwareId) {
            this.elevatorName = res.data[i].name;
            var meta = JSON.parse(res.data[i].devices[0].metadata);
            this.elevatorKl = res.data[i].devices[0].klNumber;
            break;
          }
        }
      });
      this.notificationId = params.notificationId
      this.title = this.data.title;
      this.errorCode = this.data.errorCode;
      this.errorMessage = this.data.message;
      //this.eventDate = this.data.eventDate;
      this.type = params.type;
      this.read = params.read;
      this.apiService.GetErrorDetails(this.elevatorId, this.eventDate).subscribe(res => {
        var errorExp = errorExplanation['default'];
        var errorExpExt = errorExplanationExtended['default'];

        this.terminals = errorTerminalSchema['default']['terminals'];
        for (var i = 0; i < res.data.length; i++) {
          this.terminalDataDetails[res.data[i].variable] = res.data[i].value;

          var tmp = { title: res.data[i].variable, variable: res.data[i].variable, value: res.data[i].value };
          if (typeof errorExp[res.data[i].variable] != 'undefined') {
            tmp.title = errorExp[res.data[i].variable];
          }
          this.tableDataDetails.push(tmp);
        }
        this.loadingSpinner = false;
        this.showTableDetails = true;

      });
      if (this.read === 'false' && this.notificationId != -1) {
        this.apiService.MarkAsReadEvent(this.notificationId, true).subscribe(res => {
          if (res.status === 200) {
            this.broadcastingService.changeSubject({
              id: Math.random(),
              topic: 'notificationsCount',
              action: 'markAsRead',
              type: this.type,
            });
          }
        });
      }
      this.apiService.GetConfigurationLiveStatus(this.elevatorId).subscribe(res => {
        //this.floorArray = this.createArray(res.data.length);
        for (var i = 0; i < res.data.length; i++) {
          this.floorArray.unshift(i);
        }
      });
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngAfterViewInit() { }

  ngOnDestroy() { }
}
