import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {EnterOtpDialogComponent } from './enterOtpDialog.component';

@NgModule({
  imports: [
    ThemeModule  
  ],
  exports: [EnterOtpDialogComponent],
  declarations: [
    EnterOtpDialogComponent
  ],
})
export class EnterOtpDialogModule { }
