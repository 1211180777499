


import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {PreventivesDetailsDialogComponent } from './preventivesDetailsDialog.component';
@NgModule({
  imports: [
    ThemeModule
  ],
  exports: [PreventivesDetailsDialogComponent],
  declarations: [
    PreventivesDetailsDialogComponent
  ],
})
export class PreventivesDetailsDialogModule { }
