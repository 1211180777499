import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTInterceptor, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { NbThemeService } from '@nebular/theme';
import { environment } from '@env/environment';
import { AuthGuard } from './guard/auth-guard.service';
import { RoleProvider } from './security/role.provider';
import { Role } from './enum/Role';
import {
  UserService,AuthService,ApiService
} from './data';
import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  StateService,
} from './utils';

// const socialLinks = [
//   {
//     url: 'https://github.com/akveo/nebular',
//     target: '_blank',
//     icon: 'github',
//   },
//   {
//     url: 'https://www.facebook.com/akveo/',
//     target: '_blank',
//     icon: 'facebook',
//   },
//   {
//     url: 'https://twitter.com/akveo_inc',
//     target: '_blank',
//     icon: 'twitter',
//   },
// ];

const DATA_SERVICES = [
  { provide: UserService, useClass: UserService },
  { provide: AuthService, useClass: AuthService },
  { provide: ApiService, useClass: ApiService },
];

const tokenInterceptorFilter = (req: HttpRequest<any>) => {
  if (req.url === environment.auth.endpoints.base + environment.auth.endpoints.register
    || req.url === environment.auth.endpoints.base + environment.auth.endpoints.login
    || req.url === environment.auth.endpoints.base + environment.auth.endpoints.refreshToken) {
    return true;
  }
  return false;
};

export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.auth.endpoints.base,
        login: {
          defaultErrors: ['login.error-description'],
          defaultMessages: ['login.success-description'],
        },
        register: {
          defaultErrors: ['register.error-description'],
          defaultMessages: ['register.success-description'],
        },
        logout: {
          defaultErrors: ['logout.error-description'],
          defaultMessages: ['logout.success-description'],
        },
        requestPass: {
          defaultErrors: ['request-password.error-description'],
          defaultMessages: ['request-password.success-description'],
        },
        resetPass: {
          resetPasswordTokenKey: 'token',
          requireValidToken: false,
          defaultErrors: ['reset-password.error-description'],
          defaultMessages: ['reset-password.success-description'],
        },
        token: environment.auth.token
      }),
    ],
    forms: {
      login: {
        redirectDelay: 0,
      },
      logout: {
        redirectDelay: 0,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        parent: Role.Fake,
        view: [Role.Guest],
      },
      device: {
        view: [Role.Guest],
      },
      user: {
        parent: Role.Guest,
        view: [Role.User],
        // create: '*',
        // edit: '*',
        // remove: '*',
      },
      admin: {
        parent: Role.User,
        view: [Role.Admin],
        // create: '*',
        // edit: '*',
        // remove: '*',
      },
      quality: {
        parent: Role.User,
        view: [Role.Admin],
        // create: '*',
        // edit: '*',
        // remove: '*',
      },
      system: {
        parent: Role.Admin,
        view: [Role.System],
        // create: '*',
        // edit: '*',
        // remove: '*',
      },
      fake: {
        parent: Role.Device,
        view: [Role.Fake],
        // create: '*',
        // edit: '*',
        // remove: '*',
      },
      support: {
        view: [Role.Support],
        // create: '*',
        // edit: '*',
        // remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: RoleProvider,
  },
  AuthGuard,
  AnalyticsService,
  LayoutService,
  StateService,

  { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
  {
    provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    useValue: tokenInterceptorFilter,
  },
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private themeService: NbThemeService) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    if (localStorage.getItem('theme_key')) {
      this.themeService.changeTheme(localStorage.getItem('theme_key'));
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    } as ModuleWithProviders;
  }
}
