import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'ngx-ticket-dialog',
  styleUrls: ['./ticketDialog.component.scss'],
  templateUrl: './ticketDialog.component.html',
})

export class TicketDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  
    @Input() data;
    @Input() type;
    newGroups:Array<any>=[];
    locations:Array<any>=[];
    groups:Array<any>=[];
    response:any={
        "locations":[],
        "permissions":"",
        "startDate":null,
        "endDate":null,
        "metadata":""
    }

    statuses:any=[
        {
            id:"1",
            name:this.translateService.instant('support.opened')
        },
        {
            id:"2",
            name:this.translateService.instant('support.under-processing')
        },
        {
            id:"3",
            name:this.translateService.instant('support.closed-by-kleemann')
        },
        {
          id:"4",
          name:this.translateService.instant('support.closed')
        }
    ];
    loadingSpinnerMessage=this.translateService.instant('global-tags.please-wait');
    loadingSpinner:boolean=true;
    groupLocations:Array<string>=[];
    showDetails:boolean=false;
    permissions:any=[
        {
            id:"1",
            name:this.translateService.instant('ticketDialog.permissions-view')
        },
        {
            id:"2",
            name:this.translateService.instant('ticketDialog.permissions-live')
        },
        {
            id:"3",
            name:this.translateService.instant('ticketDialog.permissions-full')
        }
    ];
    constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<TicketDialogComponent>
    ) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translateService.use(event.lang);
          });
     }

    ngOnInit() {
     if(this.type=="new")
     {
        this.response={
            "locations":[],
            "permissions":"1",
            "startDate":new Date(),
            "endDate":new Date(),
            "metadata":""
        };
        this.response.endDate.setDate(this.response.startDate.getDate() + 1);
        this.apiService.GetLocationsWithDevicesList().subscribe(res=>{
            var locations=res.data;
            this.apiService.GetMyLocationGroups().subscribe(res=>{
                this.groups=res.data;
                var groups=res.data;
                for(var i=0;i<groups.length;i++)
                {
                    var tmp={
                        name:groups[i].name,
                        groupId:groups[i].groupId,
                        children:[]
                    };
                    for(var j=0;j<groups[i].locations.length;j++)
                    {
                        for(var l = 0;l<locations.length;l++)
                        {
                            if(locations[l].locationId == groups[i].locations[j].locationId)
                            {
                                var tmpLoc={
                                    name:locations[l].name +"("+locations[l].devices[0].klNumber+")",
                                    id:this.generateUUID(),
                                    locationId:locations[l].locationId
                                }
                                tmp['children'].push(tmpLoc);
                                break;
                            }
                        }
                    }
                    this.newGroups.push(tmp);
                }
                tmp={
                    name:"Other",
                    groupId:-2,
                    children:[]
                };
                for(var l = 0;l<locations.length;l++)
                {
                    var fl=false;
                    for(var i = 0;i<this.newGroups.length;i++)
                    {
                        for(var k = 0;k<this.newGroups[i].children.length;k++)
                        {
                            if(locations[l].locationId==this.newGroups[i].children[k].locationId)
                            {
                                fl=true;
                                break;
                            }
                        }
                    }
                    if(!fl)
                    {
                        tmpLoc={
                            id:this.generateUUID(),
                            name:locations[l].name +"("+locations[l].devices[0].klNumber+")",
                            locationId:locations[l].locationId
                        }
                        tmp['children'].push(tmpLoc);
                    }
                }
                if(tmp.children.length>0)
                {
                    this.newGroups.push(tmp);
                }
                this.showDetails=true;
                this.loadingSpinner=false;
            });
         });
     }
     else
     {
        this.apiService.GetLocationsWithDevicesList().subscribe(res=>{
            this.data.locationsNames=[];
            var locations=res.data;
            for(var j=0;j<this.data.locations.length;j++)
            {
                for(var i=0;i<locations.length;i++)
                {
                    if(locations[i].locationId==this.data.locations[j])
                    {
                        this.data.locationsNames.push(locations[i].devices[0].name) 
                    }
                }
            }   
            for(var i=0;i<this.data.statuses.length;i++)
            {
                for(var j=0;j<this.statuses.length;j++)
                {
                    if(this.data.statuses[i].status==this.statuses[j].id)
                    {
                        this.data.statuses[i].name = this.statuses[j].name + " - "+moment(this.data.statuses[i].eventDate).format('DD/MM/YYYY HH:mm');
                        break;
                    }
                }
            }
            var curD=new Date();
            var enD=new Date(this.data.endDateV);
            if(enD<curD)
            {
               if(this.data.statuses[this.data.statuses.length-1]!="4")
               {
                   this.data.status=this.statuses[3].name;
                   var tmp={
                       name:this.statuses[3].name+ " - "+moment(enD).format('DD/MM/YYYY HH:mm')
                   }
                   this.data.statuses.push(tmp);
               }
            }
            
            this.loadingSpinner=false; 
            this.showDetails=true;
        }); 
     }
    }

    changeGroup(event)
    {
        if(event.includes("-1") || event.includes(-1))
        {
            if(event.length>1)
            {
                this.response.locations=[];
            }
            else
            {
                this.response.locations=[];
                for(var k=0;k<this.newGroups.length;k++)
                {
                    for(var j=0;j<this.newGroups[k].children.length;j++)
                    {
                        this.response.locations.push(this.newGroups[k].children[j].id);
                    }
                }
            }
        }
    }

    closeTicket()
    {
        this.loadingSpinner=true;
        this.apiService.UpdateTicketStatus(this.data.ticketId,'4').subscribe(res=>{
            this.loadingSpinner=false;
            this.dialogRef.close(this.data);
        });
    }

    selectCurentGroup(event,groupId)
    {
        var target = event.target || event.srcElement || event.currentTarget;
        target=target.className;
        if(target=="option-group-title")
        {
            var curGroup;
            var k=-1;
            for(var i = 0;i<this.newGroups.length;i++)
            {
                if(this.newGroups[i].groupId==groupId)
                {
                    curGroup=this.newGroups[i];
                    k=i;
                    break;
                }
            }
            var idsToRemove=[];
            for(var i=0;i<this.response.locations.length;i++)
            {
                for(var j=0;j<curGroup.children.length;j++)
                {
                    if(curGroup.children[j].id==this.response.locations[i])
                    {
                        idsToRemove.push(curGroup.children[j].id);
                    }
                }
            }
            var tmpSelectedGroups=this.response.locations;
            if(idsToRemove.length==0)
            {
                this.response.locations=[];
                for(var j=0;j<curGroup.children.length;j++)
                {
                    this.response.locations.push(this.newGroups[k].children[j].id);
                }
                for(var i=0;i<tmpSelectedGroups.length;i++)
                {
                    this.response.locations.push(tmpSelectedGroups[i]);
                }
            }
            else
            {
                tmpSelectedGroups= tmpSelectedGroups.filter((el) => !idsToRemove.includes( el ));
                this.response.locations=[];
                for(var i=0;i<tmpSelectedGroups.length;i++)
                {
                    this.response.locations.push(tmpSelectedGroups[i]);
                }
            }
        }
    }

    generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c=='x' ? r : (r&0x3|0x8)).toString(16);
        });
        return uuid;
      }

    closeDialog(){
      this.dialogRef.close();
    }

    saveDialog(){
        this.loadingSpinner=true;
        for(var i=0;i<this.response.locations.length;i++)
        {
            for(var j=0;j<this.newGroups.length;j++)
            {
                for(var k=0;k<this.newGroups[j].children.length;k++)
                {
                    if(this.response.locations[i] == this.newGroups[j].children[k].id)
                    {
                        this.response.locations[i] = this.newGroups[j].children[k].locationId;
                        break;
                    }
                }
            }
        }
        this.apiService.NewTicket(this.response).subscribe(res => {
            this.loadingSpinner=false;
            this.dialogRef.close(res.data);
        });
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}
}
