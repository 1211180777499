import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import { NotificationDetailsDialogComponent } from './notificationDetailsDialog.component';

@NgModule({
  imports: [
    ThemeModule
  ],
  exports: [NotificationDetailsDialogComponent],
  declarations: [
    NotificationDetailsDialogComponent
  ],
})
export class NotificationDetailsDialogModule { }
