import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'ngx-alert-dialog',
  styleUrls: ['./alertDialog.component.scss'],
  templateUrl: './alertDialog.component.html',
})

export class AlertDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  
    @Input() data;
    constructor(private dialogRef: NbDialogRef<AlertDialogComponent>) {}

    ngOnInit() {}

    ok(){
        this.dialogRef.close();
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}
}
