import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'ngx-group-dialog',
  styleUrls: ['./groupDialog.component.scss'],
  templateUrl: './groupDialog.component.html',
})

export class GroupDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  
    @Input() data;
    @Input() type;
    loadingSpinnerMessage=this.translateService.instant('global-tags.please-wait');
    loadingSpinner:boolean=true;
    groupLocations:Array<string>=[];
    showDetails:boolean=false;
    locations:Array<any>=[];
    response:any={'data':[]}
    constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<GroupDialogComponent>
    ) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translateService.use(event.lang);
          });
     }

    ngOnInit() {
     this.apiService.GetLocationsWithDevicesList().subscribe(res=>{
         var locations=res.data;
         for(var i=0;i<locations.length;i++)
         {
            var meta= JSON.parse(locations[i].devices[0].metadata);
            var tmp={'name':locations[i].name,'klNumber':locations[i].devices[0].klNumber,'locationId':locations[i].locationId};
            this.locations.push(tmp);
         }
         for(var i=0;i<this.data.locations.length;i++)
         {
             this.groupLocations.push(this.data.locations[i].locationId.toString());
         }
        this.showDetails=true;
        this.loadingSpinner=false;
     });
    }

    closeDialog(){
      this.dialogRef.close();
    }

    saveDialog(){
        this.response.data = this.data;
        var locs=[];
        for(var i=0;i<this.groupLocations.length;i++)
        {   
            locs.push(parseInt(this.groupLocations[i]));
        }
        var group={name:this.data.name,type:'location',locations:locs};
        if(this.type=="new")
        {
            this.apiService.PostLocationGroup(group).subscribe(res=>{
                setTimeout(() => {
                    this.dialogRef.close(this.response);
                }, 200);
            }); 
        }
        else
        {
            this.apiService.UpdateLocationGroup(group,this.data.groupId).subscribe(res=>{
                setTimeout(() => {
                    this.dialogRef.close(this.response);
                }, 200);
            }); 
        }        
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}
}
