import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'ngx-preventives-details-dialog',
  styleUrls: ['./preventivesDetailsDialog.component.scss'],
  templateUrl: './preventivesDetailsDialog.component.html',
})
 
export class PreventivesDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() elevatorId:string;
  @Input() preventiveId:number;
  @Input() title:string;
  @Input() eventDate:string;
  loadingSpinnerMessage=this.translateService.instant('global-tags.please-wait');
  loadingSpinner:boolean=true;
  @Input() message:string;
  @Input() checked:boolean=false;
  showTableDetails:boolean=false;
  elevatorName: string="";
  elevatorKl: string="";

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<PreventivesDetailsDialogComponent>
  ) {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateService.use(event.lang);
    });
  }

  ngOnInit() {
    this.apiService.GetLocationsWithDevicesList().subscribe(res=>{
        for(var i=0;i<res.data.length;i++)
        {
            if(this.elevatorId==res.data[i].devices[0].hardwareId)
            {
                this.elevatorName=res.data[i].name;
                var meta = JSON.parse(res.data[i].devices[0].metadata);
                this.elevatorKl=res.data[i].devices[0].klNumber;
                break;
            }
        }
    });
    this.loadingSpinner=false;
    this.showTableDetails=true;

  }

  closeDialog() {
    this.dialogRef.close();
  }

  resetPreventive()
  {
    this.checked=true;
    var payload={
      checked:true
    };
    this.apiService.EditPreventiveMaintenance(payload,this.preventiveId).subscribe(res=>{
    });
  }

  ngAfterViewInit() { }

  ngOnDestroy() { }
}
