import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter,map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthOAuth2JWTToken, NbAuthService } from '@nebular/auth';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService,NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@app/@core/data/user/user.service';
import { LayoutService } from '@app/@core/utils';
import { ApiService } from '@app/@core/data/api/api.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import moment from 'moment';
import { BroadcastingService } from "@app/@core/data/shared/broadcasting.service";
import {EnterOtpDialogComponent } from '../../../components/enterOtpDialog/enterOtpDialog.component';
import {RoleProvider} from '@app/@core/security/role.provider';
import { ThrowStmt } from '@angular/compiler';
import {ConfirmDialogComponent } from '../../../components/confirmDialog/confirmDialog.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  myTicket:any={};
  userPictureOnly: boolean = false;
  myElevators:any={};
  currentFlag:String='EN';
  isSupportFakeUser:boolean=false;
  languages:any=[{ title: 'English' },{ title: 'Greek' }];
  alert: any = {};
  alerts: any = [];
  hideAll:boolean=false;
  countOfElevators:number=0;
  notificationsCount: any = 0;
  notificationsBadge: string = '';
  user: any;
  showAlert: boolean = false;
  showUserMenu:boolean=false;
    userMenu=[
    { title: ""},
    { title: this.translate.instant('admin.preferences'), link: '/pages/admin' },
    { title: this.translate.instant('header.logout'), link: '/auth/logout' }
  ];
  notificationBadges = ['danger', 'warning', 'info', 'success'];
  typeAssociations = { Error: 'danger', Warning: 'warning', Info: 'info', Success: 'success' };
  now=new Date();
  mqtt = require('mqtt');
  client: any;
  constructor(private sidebarService: NbSidebarService,
    private roleProvider:RoleProvider,
    private dialogService: NbDialogService,
    private router: Router,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private apiService: ApiService,
    private translate: TranslateService,
    private userService: UserService,
    private broadcastingService: BroadcastingService,
  ) {
    this.broadcastingService.subjectChanges$.subscribe((msg: any) => {
      if(msg.topic ==='notificationsCountZero')
      {
        this.notificationsCount=0;
      }
      if (msg.topic === 'notificationsCount') {
        if (this.notificationsBadge === this.typeAssociations[msg.type]) {
          this.notificationsCount--;
        }
      }
    });

    //setInterval(()=> { this.myFunc() }, 4000);

  }

  closeTicket()
  {
    this.apiService.GetMyTicket().subscribe(res=>{
      var ticket=res.data;
      var message=this.translate.instant('support.close-ticket-query')+ticket.otp+" ?";
      this.dialogService.open(ConfirmDialogComponent, 
        {
          context: { data: message},autoFocus:true,closeOnBackdropClick:true,closeOnEsc:true,hasBackdrop:true,hasScroll:true
        }).onClose.subscribe(res =>{
          if(res.confirm)
          {
            this.apiService.UpdateTicketStatus(ticket.ticketId,'3').subscribe(res=>{
              this.router.navigate(['/auth/login'],{ queryParams:{}});
            });
          }
          else
          {
            
          }
        });
    });
    
  }

  enterOtp()
  {
    this.dialogService.open(EnterOtpDialogComponent, 
      {
        context: { },autoFocus:true,closeOnBackdropClick:false,closeOnEsc:false,hasBackdrop:true,hasScroll:true
      }).onClose.subscribe(res =>{
        if(typeof res!=="undefined")
        {
          
        }
      });
  }

  myFunc() {
    var obj = {
      notificationId: '34534634',
      message: 'message',
      elevatorId: 'hardwareId',
      title: 'title',
      eventDate: new Date(),
      type: 'Error',
      status: this.typeAssociations['Success'],
    }
    this.alerts.unshift(obj)
    this.showAlert = true;
  }

  ngOnInit() {
    this.roleProvider.getRole().subscribe(res=>{
      if(res==='support')
      {
        this.hideAll=true;
      }
      else
      {
        if(res==='fake')
        {
          this.isSupportFakeUser=true;
          this.apiService.GetMyTicket().subscribe(res=>{
            this.myTicket=res.data;
            this.checkTicketTime();
          })
         
        }
        this.apiService.GetLocationsWithDevicesList().subscribe(res=>{
          for(var i=0;i<res.data.length;i++)
          {
            var meta = JSON.parse(res.data[i].devices[0].metadata);
            this.myElevators[res.data[i].devices[0].hardwareId]={
              "name":res.data[i].name,
              "klNumber":res.data[i].devices[0].klNumber
            }
          }
        });   
      }
      this.authService.onTokenChange().subscribe((token: NbAuthOAuth2JWTToken) => {
        if (token.isValid()) {
          
          var startDate= moment().startOf('day').toISOString();
          var endDate= moment().endOf('day').toISOString();
          
          this.apiService.GetCountOfElevatorsEvents(startDate, endDate).subscribe(res => {
            this.countOfElevators=res.data[0].value;
          });
          var lng=localStorage.getItem("language");
          if(lng==null || lng=="")
          {
            this.currentFlag="EN";
          }
          else{
            this.currentFlag=lng.toUpperCase()
          }
          this.menuService.onItemClick()
          .pipe(
            filter(({ tag }) => tag === 'languages-menu'),
            map(({ item: { title } }) => title),
          )
          .subscribe(title => {
            var code='';
            switch(title){
              case 'English':
                code='en'
                break;
              case 'Greek':
                code='el'
                break;
            }
            this.currentFlag=code.toUpperCase();
            localStorage.setItem("language", code);
            this.translate.use(code);
            window.location.reload();
          });
          var errorPromise = new Promise((resolve, reject) => {
            this.apiService.GetCountNotifications('Error').subscribe(res => {
              if (res.data.length > 0) {
                resolve(res.data[0].value)
              }
            });
          });
          var warningPromise = new Promise((resolve, reject) => {
            this.apiService.GetCountNotifications('Warning').subscribe(res => {
              if (res.data.length > 0) {
                resolve(res.data[0].value)
              }
            });
          });
          var infoPromise = new Promise((resolve, reject) => {
            this.apiService.GetCountNotifications('Info').subscribe(res => {
              if (res.data.length > 0) {
                resolve(res.data[0].value)
              }
            });
  
          });
  
          Promise.all([errorPromise, warningPromise, infoPromise]).then(data => {
            for (let i = 0; i < data.length; i++) {
              if (data[i] > 0) {
                this.notificationsCount = data[i];
                this.notificationsBadge = this.notificationBadges[i];
              }
              break;
            }
          });
          this.userService.GetMyData().subscribe(res => {
            this.user = { name: res.data.firstName + " " + res.data.lastName, userId: res.data.userId,photo:res.data.photo,email:res.data.email };
            if(res.data.photo == null || res.data.photo == "null" || res.data.photo == "")
            {
              this.user.photo="assets/images/avatar.png"
            }
            if(this.hideAll || this.isSupportFakeUser)
            {
              this.userMenu = [
                { title: this.user.email},
                { title: this.translate.instant('header.logout'), link: '/auth/logout' }];
            } 
            else
            {
              this.userMenu = [{ title: this.user.email},{ title: this.translate.instant('admin.preferences'), link: '/pages/admin' },{ title: this.translate.instant('header.logout'), link: '/auth/logout' }];
            }
            this.client = this.mqtt.connect(environment.mqttUrl, { keepalive: 120,username:environment.mqttUsername,password:environment.mqttPassword });
            this.client.addListener('connect', () => this.on_connect());
            this.client.addListener('message', (topic, message) => this.on_message(topic, message));
          });
        }
      });
    });
    

    const { md } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < md),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanMd: boolean) => this.userPictureOnly = isLessThanMd);
  }

  checkTicketTime()
  {
    var curD=new Date();
    var enD=new Date(this.myTicket.endDate);
    if(enD<curD)
    {
      this.router.navigate(['/auth/logout'], {});
    }
    else
    {
      setTimeout(() => {
        this.checkTicketTime();
      }, 300000);
    }
  }
  
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  on_connect() {
    console.log("MQTT Connected!");
    this.client.subscribe('kleemann/notifications/' + this.user.userId, function (err) {
    });
  }

  onCloseAlert(alert) {
    const foundIndex = this.alerts.findIndex(({ notificationId }) => notificationId === alert.notificationId);
    this.alerts = this.alerts.filter((_, index) => index !== foundIndex);
  }

  onCloseAllAlerts() {
    this.alerts = [];
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToError(alert) {
    if(alert.type=='Error')
    {
      this.router.navigate(['/pages/errorDetails'], { queryParams: { id: alert.elevatorId, read: 'false', type: alert.type, notificationId: alert.notificationId, eventDate: alert.eventDate, title: alert.title } });
      const foundIndex = this.alerts.findIndex(({ notificationId }) => notificationId === alert.notificationId);
      this.alerts = this.alerts.filter((_, index) => index !== foundIndex);
    }
    else
    {
      const foundIndex = this.alerts.findIndex(({ notificationId }) => notificationId === alert.notificationId);
      this.alerts = this.alerts.filter((_, index) => index !== foundIndex);
    }
  }

  goToElevatorsEvents() {
    this.router.navigate(['/pages/elevators-events'], {});
  }

  on_message(topic, message) {
    var incomingObj = JSON.parse(message.toString());
    var obj = {
      notificationId: incomingObj.notificationId,
      message: incomingObj.message,
      elevatorId: incomingObj.hardwareId,
      title: incomingObj.title,
      eventDate: incomingObj.eventDate,
      type: incomingObj.type,
      status: this.typeAssociations[incomingObj.type],
    }
    this.alerts.unshift(obj)
    if (this.notificationsBadge === this.typeAssociations[incomingObj.type]) {
      this.notificationsCount++;
    }
    this.showAlert = true;
  }

  goToNotifications() {
    this.router.navigate(['/pages/notifications'], {});
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

}
