import { NbAuthOAuth2JWTToken } from '@nebular/auth';

// export const environment = {
//   mqttUrl:'wss://hydra.iti.gr/wss/',
//   mqttUsername:'kleemann',
//   mqttPassword:'rocketlift',
//   production: false,
//   auth: {
//     endpoints: {
//       base: 'http://localhost:3000/auth/',
//       register: 'register',
//       activate: 'activate',
//       login: 'login',
//       logout: 'logout',
//       refreshToken: 'refresh-token',
//       requestPass: 'request-pass',
//       resetPass: 'reset-pass',
//     },
//     token: {
//       class: NbAuthOAuth2JWTToken,
//       key: 'data',
//     }
//   },
//   api: {
//     endpoints: {
//       base: 'http://localhost:3000/api/',
//       keycloak: 'https://login.kleemann.gr/auth/realms/3s/protocol/openid-connect/',
//       redirect_uri_keycloak:'https://kleemann.pragma-iot.com/auth/keycloak',
//       redirect_uri_keycloakMobile:'https://kleemann.pragma-iot.com/auth/keycloakMobile',
//       client_secret_keycloak:'9bedb88e-c899-4ebf-9906-5eadf14d1549',
//       client_id_keycloak:'pragma-iot',
//       googleUrl:'https://maps.googleapis.com/maps/api',
//       googleApiKey:'AIzaSyBKyw2NgnkPRSC0SyHuVe_o_hLofBOyU5A'
//     },
//   },
//   dateFormat: 'yyyy-MM-dd',
// };

export const environment = {
  mqttUrl:'wss://hydra.iti.gr/wss/',
  mqttUsername:'kleemann',
  mqttPassword:'rocketlift',  
  production: false,
  auth: {
    endpoints: {
      base: 'https://kleemann.pragma-iot.com/api/auth/',
      register: 'register',
      activate: 'activate',
      login: 'login',
      logout: 'logout',
      refreshToken: 'refresh-token',
      requestPass: 'request-pass',
      resetPass: 'reset-pass',
    },
    token: {
      class: NbAuthOAuth2JWTToken,
      key: 'data',
    }
  },
  api: {
    endpoints: {
      base: 'https://kleemann.pragma-iot.com/api/api/',
      keycloak: 'https://login.kleemann.gr/auth/realms/3s/protocol/openid-connect/',
      redirect_uri_keycloak:'https://kleemann.pragma-iot.com/auth/keycloak',
      redirect_uri_keycloakMobile:'https://kleemann.pragma-iot.com/auth/keycloakMobile',
      client_secret_keycloak:'9bedb88e-c899-4ebf-9906-5eadf14d1549',
      client_id_keycloak:'pragma-iot',
      googleUrl:'https://maps.googleapis.com/maps/api',
      googleApiKey:'AIzaSyBKyw2NgnkPRSC0SyHuVe_o_hLofBOyU5A'
    },
  },
  dateFormat: 'yyyy-MM-dd',
};

// export const environment = {
//   mqttUrl:'wss://live.kleemannlifts.com/wss/',
//   mqttUsername:'kleemann',
//   mqttPassword:'rocketlift',
//   production: false,
//   auth: {
//     endpoints: {
//       base: 'https://live.kleemannlifts.com/api/auth/',
//       register: 'register',
//       activate: 'activate',
//       login: 'login',
//       logout: 'logout',
//       refreshToken: 'refresh-token',
//       requestPass: 'request-pass',
//       resetPass: 'reset-pass',
//     },
//     token: {
//       class: NbAuthOAuth2JWTToken,
//       key: 'data',
//     }
//   },
//   api: {
//     endpoints: {
//       base: 'https://live.kleemannlifts.com/api/api/',
//       keycloak: 'https://login.kleemannlifts.com/auth/realms/3s/protocol/openid-connect/',
//       redirect_uri_keycloak:'https://live.kleemannlifts.com/auth/keycloak',
//       redirect_uri_keycloakMobile:'https://live.kleemannlifts.com/auth/keycloakMobile',
//       client_secret_keycloak:'ea451611-f847-45d0-9dac-56040dd863f8',
//       client_id_keycloak:'live-kleemannlifts',
//       googleUrl:'https://maps.googleapis.com/maps/api',
//       googleApiKey:'AIzaSyBKyw2NgnkPRSC0SyHuVe_o_hLofBOyU5A'
//     },
//   },
//   dateFormat: 'yyyy-MM-dd',
// };