import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'ngx-confirm-dialog',
  styleUrls: ['./confirmDialog.component.scss'],
  templateUrl: './confirmDialog.component.html',
})

export class ConfirmDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  
    @Input() data;
    response:any={'confirm':false}

    constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<ConfirmDialogComponent>
    ) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translateService.use(event.lang);
          });
     }

    ngOnInit() {
     
    }

    closeDialog(){
        this.dialogRef.close(this.response);
    }

    saveDialog(){
        this.response.confirm=true;
        this.dialogRef.close(this.response);
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}
}
