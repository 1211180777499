import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {LocationDialogComponent } from './locationDialog.component';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  imports: [
    ThemeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBKyw2NgnkPRSC0SyHuVe_o_hLofBOyU5A'
    }),
  ],
  exports: [LocationDialogComponent],
  declarations: [
    LocationDialogComponent
  ],
})
export class LocationDialogModule { }
