import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import { NbToastrService } from '@nebular/theme';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-enterOtp-dialog',
  styleUrls: ['./enterOtpDialog.component.scss'],
  templateUrl: './enterOtpDialog.component.html',
})

export class EnterOtpDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  
    otp:string="";
    otpIsValid:boolean=false;
    otpValidationEnded:boolean=false;
    redirectData:any={};
    loadingSpinnerMessage=this.translateService.instant('global-tags.please-wait');
    loadingSpinner:boolean=false;
    constructor(
      private router: Router,
    private toastrService: NbToastrService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<EnterOtpDialogComponent>
    ) {
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translateService.use(event.lang);
          });
     }

    ngOnInit() {
        this.otp='';
        this.otpIsValid=false;
    }

    closeDialog(){
      this.dialogRef.close();
    }

    validateOtp()
    {
      this.loadingSpinner=true;
      var payload:any={
        "otp":this.otp
      }
      this.apiService.ValidateTicket(payload).subscribe(res=>{
        if(res.data.ticketIsValid)
        {
          this.otpIsValid = true;
          this.otpValidationEnded = true;
          var message=this.translateService.instant('enterOtpDialog.ticketValid');
          this.showToast('top-right','success',message);
          this.redirectData=res.data;
        }
        else
        {
          var message=this.translateService.instant('enterOtpDialog.ticketNotValid');
          this.showToast('top-right','danger',message);
        }
        this.loadingSpinner=false;
      });
    }

    showToast(position, status,message) {
      this.toastrService.show(
        '',
        message,
        { position, status });
    }

    saveDialog(){
      this.loadingSpinner=true;
      this.apiService.UpdateTicketStatus(this.redirectData.ticketId,'2').subscribe(res=>{
        this.loadingSpinner=false;
        this.router.navigate(['/auth/supportLogin'],{ queryParams:{ username: this.redirectData.username,password:this.otp}});
      });
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}
}
