import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { Router } from '@angular/router';
import { NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(private analytics: AnalyticsService, public translate: TranslateService, private router: Router,private iconLibraries: NbIconLibraries) {
    /**
     * Register Pragma Custom Font
     * 
     * Usage on nb-icon
     * <nb-icon icon="icon-***" pack="pragma-font"></nb-icon>
     * 
     **/
    this.iconLibraries.registerFontPack('pragma-font', { iconClassPrefix: 'pragma' });
  }

  ngOnInit(): void {
    var uri=window.location.href;

    if(uri.includes("keycloak") || uri.includes("keycloakMobile") || uri.includes("redirectLogin") || uri.includes("redirectLoginMobile"))
    {
    }
    else
    {
      if(sessionStorage.getItem('auth_app_token')==null)
      {
        localStorage.removeItem('auth_app_token');
        this.router.navigateByUrl('/auth/login');
      }
    }
    this.translate.setDefaultLang('en');
    var lng=localStorage.getItem("language");
    if(lng==null || lng=="")
    {
      this.translate.use('en');
    }
    else
    {
      this.translate.use(lng);
    }
    this.analytics.trackPageViews();
  }
}