import { delay, reduce } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import * as moment from 'moment';
import { ApiService } from '@app/@core/data/api/api.service';
import { Router } from '@angular/router';
import * as eventLogMessage from '../../../assets/files/eventLogMessage.json';
import * as eventLogType from '../../../assets/files/eventLogType.json';
import { ErrorDetailsDialogComponent } from '../errorDetailsDialog/errorDetailsDialog.component';

@Component({
  selector: 'ngx-notification-details-dialog',
  styleUrls: ['./notificationDetailsDialog.component.scss'],
  templateUrl: './notificationDetailsDialog.component.html',
})

export class NotificationDetailsDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data;
  showErrorButton: boolean = false;
  showBody: boolean = false;
  myElevators: any = {};
  response: any = { 'data': [] }
  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogRef: NbDialogRef<NotificationDetailsDialogComponent>,
    private dialogService: NbDialogService
  ) { }

  ngOnInit() {
    this.apiService.GetLocationsWithDevicesList().subscribe(res => {
      for (var i = 0; i < res.data.length; i++) {
        var meta = JSON.parse(res.data[i].devices[0].metadata);
        this.myElevators[res.data[i].devices[0].hardwareId] = {
          "name": res.data[i].name,
          "klNumber": res.data[i].devices[0].klNumber
        }
      }
      this.showBody = true;
      if (this.data.type == "Error") {
        this.showErrorButton = true;
      }
    });

  }

  goToError() {
    var date = moment(this.data.eventDate).toISOString();
    this.data['errorCode'] = this.data.code.split("Error Code ").pop();

    this.dialogService.open(ErrorDetailsDialogComponent,
      {
        context: { eventDate: date, data: this.data }, hasScroll: true
      }).onClose.subscribe(res => {
        if (typeof res !== "undefined") {
        }
      });
    //this.router.navigate(['/pages/errorDetails'], { queryParams: { id: this.data.hardwareId, read: this.data.read, type:this.data.type, notificationId: this.data.notificationId,eventDate:this.data.eventDate,title:this.data.code } });
    //this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveDialog() {
    this.dialogRef.close(this.response);
  }

  ngAfterViewInit() { }

  ngOnDestroy() { }
}
