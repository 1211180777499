import { Component, Input, OnInit,ViewChild,ElementRef } from '@angular/core';
import {DefaultEditor } from 'ng2-smart-table';
import { ApiService } from '@app/@core/data';
@Component({
  selector: 'app-custom-table-date',
  template: `
   {{value | date:'dd/MM/yyyy HH:mm:ss'}}
`
})
export class CustomTableDateComponent extends DefaultEditor implements OnInit {
 
  @Input() value: any;
  @Input() rowData: any;

  constructor(private apiService: ApiService) { 
    
    super();
  }


  ngOnInit() {
  }

}