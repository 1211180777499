import { NgModule } from '@angular/core';

import { ThemeModule } from '@app/@theme/theme.module';
import { MaintenanceEventDialogComponent } from './maintenance-event-dialog.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {RoleProvider} from '@app/@core/security/role.provider';

@NgModule({
  imports: [
    ThemeModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
   
  ],
  exports: [MaintenanceEventDialogComponent],
  providers:[RoleProvider],
  declarations: [
    MaintenanceEventDialogComponent
  ],
})
export class MaintenanceEventDialogModule { }
