import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import { NewWidgetDialogComponent } from './newWidgetDialog.component';

@NgModule({
  imports: [
    ThemeModule
  ],
  exports: [NewWidgetDialogComponent],
  declarations: [
    NewWidgetDialogComponent
  ],
})
export class NewWidgetDialogModule { }
