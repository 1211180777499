import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {UserDialogComponent } from './userDialog.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
@NgModule({
  imports: [
    ThemeModule,AutocompleteLibModule
  ],
  exports: [UserDialogComponent],
  declarations: [
    UserDialogComponent
  ],
})
export class UserDialogModule { }
