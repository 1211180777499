import { Component, Input, OnInit,ViewChild,ElementRef } from '@angular/core';
import {DefaultEditor } from 'ng2-smart-table';
import { ApiService } from '@app/@core/data/api/api.service';

@Component({
  selector: 'app-select',
  template: `
  <nb-select [(ngModel)]="value" (selectedChange)="checkValue()" *ngIf="showSelect">
    <nb-option *ngFor="let location of locations" value="location.locationId">{{location.name}}</nb-option>
    </nb-select>
  `
})
export class SelectLocationComponent extends DefaultEditor implements OnInit {
 
  @Input() value: any;
  @Input() rowData: any;
  locations:any;
  showSelect:boolean=false;
  constructor(private apiService: ApiService) { 
    super();
  }

  ngOnInit() {

    this.locations=[];
    this.apiService.GetLocationsWithDevicesList().subscribe(res=>{
        this.locations=res.data;
        if(typeof this.cell!="undefined")
        {
            this.value=this.cell.getRow().getData().locationId;
            this.showSelect=true;
        }
    });
    
  }

  checkValue()
  {
    this.cell.newValue=this.value;
  }

}