import { Component, Input, OnInit,ViewChild,ElementRef } from '@angular/core';
import {DefaultEditor } from 'ng2-smart-table';

@Component({
  selector: 'app-checkbox',
  template: `
    <input 
      type="checkbox"
      [(ngModel)]="value" [disabled]="isDisabled"
      (change)="checkValue()">
  `
})
export class CheckboxComponent extends DefaultEditor implements OnInit {
 
  @Input() value: any;
  @Input() rowData: any;

  isDisabled: boolean;

  constructor() { 
    super();
  }


  ngOnInit() {
    this.isDisabled=true;
    if(typeof this.cell!="undefined")
    {
      this.isDisabled=false;
      this.value=this.cell.newValue;
    }
  }

  checkValue()
  {
    this.cell.newValue=this.value;
  }

}