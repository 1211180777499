import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {GroupDialogComponent } from './groupDialog.component';


@NgModule({
  imports: [
    ThemeModule,
  ],
  exports: [GroupDialogComponent],
  declarations: [
    GroupDialogComponent
  ],
})
export class GroupDialogModule { }
