import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '@app/@core/data/api/api.service';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { CalendarEvent } from 'calendar-utils';
import { UserService } from '@app/@core/data/user/user.service';
import {RoleProvider} from '@app/@core/security/role.provider';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-maintenance-event-dialog',
  templateUrl: './maintenance-event-dialog.component.html',
  styleUrls: ['./maintenance-event-dialog.component.scss']
})
export class MaintenanceEventDialogComponent implements OnInit {
  @Input() mode: string;
  @Input() eventId;
  @Input() hardwareId;
  loadingSpinnerMessage=this.translateService.instant('global-tags.please-wait');
  loadingSpinner:boolean=true;
  header:string;
  showUsers:boolean=false;
  allowUsers:boolean=false;
  isDisabled:boolean;
  showDetails:boolean=false;
  showElevators:boolean=false;
  showbuildingManager:boolean=false;
  response:any= 
  { 
    title: "",
    message: "", 
    type:"3",
    hardwareId:"", 
    userId:-1,
    eventDate: new Date(),
    meta:{
      notifications:[],
      triggerNotification:false,
      buildingManagerName:"",
      buildingManagerPhone:""
    },
    metadata:{
      notifications:[],
      triggerNotification:false,
      buildingManagerName:"",
      buildingManagerPhone:""
    }
  };
  notificationTypes=[
    {
      "name":this.translateService.instant('maintenance-event-dialog-1.5mins'),
      "value":"5minutes"
    },
    {
      "name":this.translateService.instant('maintenance-event-dialog-1.15mins'),
      "value":"15minutes"
    },
    {
      "name":this.translateService.instant('maintenance-event-dialog-1.30mins'),
      "value":"30minutes"
    },
    {
      "name":this.translateService.instant('maintenance-event-dialog-1.1hrs'),
      "value":"1hours"
    },
    {
      "name":this.translateService.instant('maintenance-event-dialog-1.3hrs'),
      "value":"3hours"
    },
    {
      "name":this.translateService.instant('maintenance-event-dialog-1.6hrs'),
      "value":"6hours"
    },
    {
      "name":this.translateService.instant('maintenance-event-dialog-1.12hrs'),
      "value":"12hours"
    },
    {
      "name":this.translateService.instant('maintenance-event-dialog-1.1day'),
      "value":"1day"
    }
  ];
  colors = [
    { "id": "1", "name": "RED" },
    { "id": "2", "name": "ORANGE" },
    { "id": "3", "name": "BLUE" },
    { "id": "4", "name": "GREEN" },
  ];
  elevators:Array<any> = [];
  users:Array<any> = [];

  constructor(
    private apiService: ApiService,
    private userService:UserService,
    private router: Router,
    private roleProvider:RoleProvider,
    private dialogRef: NbDialogRef<MaintenanceEventDialogComponent>,
    private translateService: TranslateService
  ) { 
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translateService.use(event.lang);
      });
    }

  ngOnInit() {
    this.roleProvider.getRole().subscribe(res=>{

        if(res=="admin" || res=="system_admin" || res=="user")
        {
          this.userService.GetUserList().subscribe(res=>{
            this.users=res.data;
            if(this.users.length>0)
            {
              this.allowUsers=true;
            } 
          });
          ;
        }

      
      this.apiService.GetLocationsWithDevicesList().subscribe(res =>{
        for(var i=0;i<res.data.length;i++)
        {
          var tmp:any=
          {
            "hardwareId":res.data[i].devices[0].hardwareId,
            "name":res.data[i].name,
            "buildingManagerName":"",
            "buildingManagerPhone":""
          }
          var meta=JSON.parse(res.data[i].devices[0].metadata);
          if(typeof meta.buildingManagerName!="undefined")
          {
            tmp.buildingManagerName=meta.buildingManagerName;
          }
          if(typeof meta.buildingManagerPhone!="undefined")
          {
            tmp.buildingManagerPhone=meta.buildingManagerPhone;
          }
          tmp.klNumber=res.data[i].devices[0].klNumber;
  
          this.elevators.push(tmp);
        }
        if(this.mode=="new")
        {
          if(this.hardwareId!="")
          {
            this.response.hardwareId=this.hardwareId;
          }
          else
          {
            this.response.hardwareId=this.elevators[0].hardwareId;
          }
         
          this.changeElevator(this.response.hardwareId);
          this.header=this.translateService.instant('maintenance-event-dialog-1.new-maintenance-event');
          this.showDetails=true;
          this.showUsers=true;
        }
        else
        {
          this.apiService.GetCurrentElevatorEvent(this.eventId).subscribe(res=>{
            this.response.title=res.data.title;
            this.response.hardwareId=res.data.hardwareId;
            this.response.message=res.data.message;
            this.response.type=res.data.type;
            this.response.userId=res.data.userId.toString();
            this.response.eventDate=res.data.eventDate;
            if(res.data.metadata!="")
            {
              this.response.meta=JSON.parse(res.data.metadata);
              this.showbuildingManager=true;
            }
            if(this.mode=="edit")
            {
              this.header=this.translateService.instant('maintenance-event-dialog-1.edit-maintenance-event');
            }
            else
            {
              this.header=this.translateService.instant('maintenance-event-dialog-1.view-maintenance-event');
              this.isDisabled=true;
            }
            this.showDetails=true;
            this.showUsers=true;
          });
        }
        this.loadingSpinner=false;
        this.showElevators=true;
      }); 
    });
  }

  changeElevator(event)
  {
    this.showbuildingManager=false;
    for(var i=0;i<this.elevators.length;i++)
    {
      if(this.elevators[i].hardwareId==event)
      {
        this.response.meta.buildingManagerName=this.elevators[i].buildingManagerName;
        this.response.meta.buildingManagerPhone=this.elevators[i].buildingManagerPhone;
       
        break;
      }
    }
    this.showbuildingManager=true;
  }
  closeDialog() {
    this.dialogRef.close();
  }

  saveDialog() {
    if (this.mode === 'new') 
    {
      if(this.response.userId==-1 || !this.showUsers)
      {
        delete this.response.userId;
      }
      else
      {
        this.response.userId=parseInt(this.response.userId);
      }
      this.response.metadata=JSON.stringify(this.response.meta);
      this.apiService.CreateEventsMaintenance(this.response).subscribe(res => {
        this.dialogRef.close(res.data);
      });
    } 
    else if (this.mode === 'edit') {
      this.response.userId=parseInt(this.response.userId);
      this.response.metadata=JSON.stringify(this.response.meta);
      this.apiService.EditEventsMaintenance(this.response,this.eventId).subscribe(res => {
        this.dialogRef.close(this.response);
      });
    }
    else{
      this.dialogRef.close();
    }
  }
}
