import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import { UserService } from '@app/@core/data/user/user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { RoleProvider } from '@app/@core/security/role.provider';

@Component({
  selector: 'ngx-location-dialog',
  styleUrls: ['./locationDialog.component.scss'],
  templateUrl: './locationDialog.component.html',
})

export class LocationDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  
    @Input() data;
    showDetails:boolean=false;
    loadingSpinnerMessage=this.translateService.instant('global-tags.please-wait');
    loadingSpinner:boolean=true;
    currentGatewayId:string="";
    response:any={'data':[]};
    isAdmin:boolean=false;
    constructor(
      private roleProvider: RoleProvider,
    private apiService: ApiService,
    private userService: UserService,
    private translateService: TranslateService,
    private dialogRef: NbDialogRef<LocationDialogComponent>
    ) { this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translateService.use(event.lang);
      });}

    ngOnInit() {
      this.roleProvider.getRole().subscribe(res => {
        if(res==='fake' || res==='admin' || res==='quality')
        {
          this.isAdmin=true;
        }
      });
     this.data.latitude=parseFloat(this.data.latitude);
     this.data.longitude=parseFloat(this.data.longitude);
     this.data.centerLatitude=parseFloat(this.data.latitude);
     this.data.centerLongitude=parseFloat(this.data.longitude);
     this.currentGatewayId=this.data.gatewayId;
     this.showDetails=true;
     this.loadingSpinner=false;
    }
    closeDialog(){
      this.dialogRef.close();
    }

    saveDialog(){
        this.response.data = this.data;
        if(this.data.gatewayId!==this.currentGatewayId)
        {
          var payload={'gatewayId':this.data.gatewayId};
          this.apiService.UpdateDevice(payload,this.data.deviceId).subscribe(res=>{

          });
        }
        var loc={name:this.data.name,latitude:this.data.latitude.toString(),longitude:this.data.longitude.toString(),address:this.data.address};
        this.apiService.UpdateLocation(loc,this.data.locationId).subscribe(res=>{
            setTimeout(() => {
                this.dialogRef.close(this.response);
            }, 200);
        });
        
    }

    mapClick(event)
    {
        this.data.latitude=event.coords.lat;
        this.data.longitude=event.coords.lng;
    }
    ngAfterViewInit() {}

    ngOnDestroy() {}
}