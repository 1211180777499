import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiService } from '@app/@core/data/api/api.service';
import * as widgetsJson  from '../../../assets/files/widgets.json';
import * as dashboardWidgetsJson  from '../../../assets/files/dashboardWidgets.json';

@Component({
  selector: 'ngx-new-widget-dialog',
  styleUrls: ['./newWidgetDialog.component.scss'],
  templateUrl: './newWidgetDialog.component.html',
})

export class NewWidgetDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  
    @Input() data;
    @Input() type;
    widgets = widgetsJson['default'];
    response:any={'data':[]}
    constructor(
    private apiService: ApiService,
    private dialogRef: NbDialogRef<NewWidgetDialogComponent>
    ) { }

    ngOnInit() {
      if(this.type=='overview')
      {
        this.widgets = widgetsJson['default'];
      }
      else if(this.type=='dashboard')
      {
        this.widgets = dashboardWidgetsJson['default'];
      }
      
      var new_widgets = [];
      this.data.forEach(element => {
        new_widgets.push(element.type)
      });
      this.widgets.forEach(element => {
        if(new_widgets.includes(element.type)) {
          element.selected = true;
          element.status = 'success';
        }
        else {
          element.selected = false;
          element.status = '';
        }
      });
    }

    checkWidget(type)
    {
      for(var i=0;i<this.widgets.length;i++)
      {
        if(this.widgets[i]['type']==type)
        {
          this.widgets[i]['selected']=!this.widgets[i]['selected'];
          if(this.widgets[i]['status'] === '') {
            this.widgets[i]['status'] = 'success';
          }
          else {
            this.widgets[i]['status'] = '';
          }
        }
      }
    }
    closeDialog(){

      this.dialogRef.close();
    }

    saveDialog(){
      this.response.data = [];
      for(var i=0;i<this.widgets.length;i++)
      {
        if(this.widgets[i].selected)
        {
          var found = this.data.find(element => {
            return element.type == this.widgets[i].type
          });
          if(!found) {
            this.response.data.push({widget:{
              x: this.widgets[i].x,
              y: this.widgets[i].y,
              cols: this.widgets[i].cols,
              rows: this.widgets[i].rows,
              type: this.widgets[i].type,
            }, action: 'add'});
          }
          //else {
          //  this.response.data.push({widget: {
          //    x: this.widgets[i].x,
          //    y: this.widgets[i].y,
          //    cols: this.widgets[i].cols,
          //    rows: this.widgets[i].rows,
          //    type: this.widgets[i].type,
          //  }, action: 'remove'});
          //}
        }
        else {
          var found = this.data.find(element => {
            return element.type == this.widgets[i].type
          });
          if(found) {
            this.response.data.push({widget:{
              x: this.widgets[i].x,
              y: this.widgets[i].y,
              cols: this.widgets[i].cols,
              rows: this.widgets[i].rows,
              type: this.widgets[i].type,
            }, action: 'remove'});
          }
        }
      }
      setTimeout(() => {
        this.dialogRef.close(this.response);
      }, 100);
    }

    ngAfterViewInit() {}

    ngOnDestroy() {}
}
