import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NewWidgetDialogModule } from '@app/components/newWidgetDialog/newWidgetDialog.module';
import { NewWidgetDialogComponent } from '@app/components/newWidgetDialog/newWidgetDialog.component';
import { NotificationDetailsDialogModule } from '@app/components/notificationDetailsDialog/notificationDetailsDialog.module';
import { NotificationDetailsDialogComponent } from '@app/components/notificationDetailsDialog/notificationDetailsDialog.component';
import { UserDialogModule } from '@app/components/userDialog/userDialog.module';
import { UserDialogComponent } from '@app/components/userDialog/userDialog.component';
import { ErrorDetailsDialogModule } from '@app/components/errorDetailsDialog/errorDetailsDialog.module';
import { PreventivesDetailsDialogModule } from '@app/components/preventivesDetailsDialog/preventivesDetailsDialog.module';
import { ErrorDetailsDialogComponent } from '@app/components/errorDetailsDialog/errorDetailsDialog.component';
import { PreventivesDetailsDialogComponent } from '@app/components/preventivesDetailsDialog/preventivesDetailsDialog.component';
import { LocationDialogModule } from '@app/components/locationDialog/locationDialog.module';
import { ConfirmDialogModule } from '@app/components/confirmDialog/confirmDialog.module';
import { LocationDialogComponent } from '@app/components/locationDialog/locationDialog.component';
import { ConfirmDialogComponent } from '@app/components/confirmDialog/confirmDialog.component';
import { GroupDialogModule } from '@app/components/groupDialog/groupDialog.module';
import { GroupDialogComponent } from '@app/components/groupDialog/groupDialog.component';
import { TicketDialogModule } from '@app/components/ticketDialog/ticketDialog.module';
import { TicketDialogComponent } from '@app/components/ticketDialog/ticketDialog.component';
import { EnterOtpDialogModule } from '@app/components/enterOtpDialog/enterOtpDialog.module';
import { EnterOtpDialogComponent } from '@app/components/enterOtpDialog/enterOtpDialog.component';
import { MaintenanceEventDialogComponent } from '@app/components/maintenance-event-dialog/maintenance-event-dialog.component';
import { MaintenanceEventDialogModule } from '@app/components/maintenance-event-dialog/maintenance-event-dialog.module';
import { NewDashboardDialogComponent } from './components/new-dashboard-dialog/new-dashboard-dialog.component';
import { NewDashboardDialogModule } from '@app/components/new-dashboard-dialog/new-dashboard-dialog.module';
import { AlertDialogModule } from '@app/components/alertDialog/alertDialog.module';
import { AlertDialogComponent } from '@app/components/alertDialog/alertDialog.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [AppComponent],
  imports: [
    QRCodeModule,
    EnterOtpDialogModule,
    NewDashboardDialogModule,
    MaintenanceEventDialogModule,
    GroupDialogModule,
    TicketDialogModule,
    UserDialogModule,
    ErrorDetailsDialogModule,
    PreventivesDetailsDialogModule,
    LocationDialogModule,
    ConfirmDialogModule,
    AlertDialogModule,
    NewWidgetDialogModule,
    NotificationDetailsDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxDaterangepickerMd.forRoot(),
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
  ],
  entryComponents:[
    EnterOtpDialogComponent,
    NewWidgetDialogComponent,
    NotificationDetailsDialogComponent,
    UserDialogComponent,
    PreventivesDetailsDialogComponent,
    ErrorDetailsDialogComponent,
    LocationDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    GroupDialogComponent,
    TicketDialogComponent,
    MaintenanceEventDialogComponent,
    NewDashboardDialogComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
})
export class AppModule {
}