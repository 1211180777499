import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import { NewDashboardDialogComponent } from './new-dashboard-dialog.component';

@NgModule({
  declarations: [NewDashboardDialogComponent],
  exports: [NewDashboardDialogComponent],
  imports: [
    ThemeModule
  ]
})

export class NewDashboardDialogModule { }