import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { forkJoin } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ApiService {

  private keycloakUrl = environment.api.endpoints.keycloak;
  private keycloakRedirectLoginMobile = environment.api.endpoints.redirect_uri_keycloakMobile;
  private keycloakRedirectLogin = environment.api.endpoints.redirect_uri_keycloak;
  private keycloakClientSecret = environment.api.endpoints.client_secret_keycloak;
  private keycloakClientId = environment.api.endpoints.client_id_keycloak;
  private apiUrl = environment.api.endpoints.base;
  private googleUrl = environment.api.endpoints.googleUrl;
  private googleApiKey = environment.api.endpoints.googleApiKey;
  constructor(private http: HttpClient) { }

  public CreateEventsMaintenance(data: any): Observable<any> {
    var url = this.apiUrl + 'maintenance';
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public NewTicket(data: any): Observable<any> {
    var url = this.apiUrl + 'ticket';
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public ValidateTicket(data: any): Observable<any> {
    var url = this.apiUrl + 'ticket/validate';
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetTicketsByDate(startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'ticket/listByDate?startDate=' + startDate + "&endDate=" + endDate;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetMyTicket(): Observable<any> {
    var url = this.apiUrl + 'ticket/myTicket';
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public UpdateTicketStatus(ticket: any,status:string): Observable<any> {
    var data:any = {"status":status};
    var url = this.apiUrl + 'ticket/updateStatus?id=' + ticket;
    return this.http.put<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public EditEventsMaintenance(data: any, eventId: any): Observable<any> {
    var url = this.apiUrl + 'maintenance?id=' + eventId;
    return this.http.put<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public CompleteEventsMaintenance(eventId: any): Observable<any> {
    var data:any = {"completed":true};
    var url = this.apiUrl + 'maintenance/complete?id=' + eventId;
    return this.http.put<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public DeleteEventsMaintenance(eventId: any): Observable<any> {
    var url = this.apiUrl + 'maintenance?id=' + eventId;
    return this.http.delete<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetDetailsElevatorById(hardwareId: string): Observable<any> {
    var url = this.apiUrl + '3S/list?hardwareId=' + hardwareId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('GetDetailsElevatorById'))
    );
  }
  public GetWidgetDetails(id: number): Observable<any> {
    var url = this.apiUrl + 'widget?id=' + id;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('GetDetailsElevatorById'))
    );
  }

  public UpdateWidget(widgetId: number,data: any): Observable<any> {
    var url = this.apiUrl + "widget?id=" + widgetId;
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public DeleteDashboard(hardwareId: number): Observable<any> {
    var url = this.apiUrl + 'dashboard?id=' + hardwareId;
    return this.http.delete<any>(url, httpOptions).pipe(
      catchError(this.handleError('DeleteDashboard'))
    );
  }
  public FetchEventsMaintenance(startDate: string, endDate: string, elevatorId: string): Observable<any> {
    var url = this.apiUrl + 'maintenance/listByElevator?startDate=' + startDate + "&endDate=" + endDate + "&hardwareId=" + elevatorId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetMaintenanceMessages(hardwareId: string, startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'preventives/list?hardwareId=' + hardwareId + "&startDate=" + startDate + "&endDate=" + endDate
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public EditPreventiveMaintenance(data: any, preventiveId: any): Observable<any> {
    var url = this.apiUrl + 'preventives?id=' + preventiveId;
    return this.http.put<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public ResetPreventiveMaintenance(data: any,hardwareId: any): Observable<any> {
    var url = this.apiUrl + 'preventives/resetAll?hardwareId=' + hardwareId;
    return this.http.put<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetCurrentElevatorEvent(id: number): Observable<any> {
    var url = this.apiUrl + 'maintenance?id=' + id;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public FetchAllElevatorsEvents(startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'maintenance/list?startDate=' + startDate + "&endDate=" + endDate;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  GetCoordinatesByAddress(address: String): Observable<any> {
    const url = this.googleUrl + '/geocode/json?address=' + address + '&key=' + this.googleApiKey;
    return this.http.get<any>(url);
  }

  GetAddressByCoordinates(lat: number, lng: number): Observable<any> {
    const url = this.googleUrl + '/geocode/json?latlng=' + lat + "," + lng + '&key=' + this.googleApiKey;
    var httpOptions = { withCredentials: false, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.get<any>(url, httpOptions);
  }

  public GetLocationsList(): Observable<any> {
    var url = this.apiUrl + 'location/list'
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetLocationsListByUser(userId: number): Observable<any> {
    var url = this.apiUrl + 'location/listByUser?userId=' + userId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetPhotosByElevator(hardwareId: string): Observable<any> {
    var url = this.apiUrl + 'photo/listByElevator?hardwareId=' + hardwareId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetGroupsListByUser(userId: number): Observable<any> {
    var url = this.apiUrl + 'groups/listByUser?userId=' + userId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetGroup(groupId: number): Observable<any> {
    var url = this.apiUrl + 'groups?groupId=' + groupId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public UpdateUserLocations(data: any, userId: number): Observable<any> {
    var url = this.apiUrl + "location/updateUserLocations?userId=" + userId;
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public UpdateLocation(data: any, locationId: number): Observable<any> {
    var url = this.apiUrl + "location?locationId=" + locationId;
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public UpdateLocationQualityControl(id: string): Observable<any> {
    var url = this.apiUrl + "location/qualityControl?id=" + id;
    return this.http.put(url, {}, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public UpdateDevice(data: any, deviceId: number): Observable<any> {
    var url = this.apiUrl + "device?id=" + deviceId;
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public UpdateDeviceQRCode(data: any, deviceId: string): Observable<any> {
    var url = this.apiUrl + "device/qrcode?id=" + deviceId;
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public UpdateLocationGroup(data: any, groupId: number): Observable<any> {
    var url = this.apiUrl + "groups?groupId=" + groupId;
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public PostLocationGroup(data: any): Observable<any> {
    var url = this.apiUrl + "groups";
    return this.http.post(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public PostPhoto(data: any): Observable<any> {
    var url = this.apiUrl + "photo";
    return this.http.post(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public PostUserPhoto(data: any): Observable<any> {
    var url = this.apiUrl + "photo/user";
    return this.http.post(url, data, httpOptions)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public DeleteLocationGroup(groupId: number): Observable<any> {
    var url = this.apiUrl + "groups?groupId=" + groupId;
    return this.http.delete(url)
      .pipe(
        catchError(this.handleError('updateUser'))
      );
  }

  public GetMyLocationGroups(): Observable<any> {
    var url = this.apiUrl + 'groups/list?type=location'
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetLocationsWithDevicesList(): Observable<any> {
    var url = this.apiUrl + 'location/listWithDevices'
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetCurrentLocationWithDevicesList(hardwareId:string): Observable<any> {
    var url = this.apiUrl + 'location/getOneWithDevices?hardwareId='+hardwareId
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetCountOfElevatorsEvents(startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'maintenance/count?startDate=' + startDate + "&endDate=" + endDate;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetCountNotifications(type: string): Observable<any> {
    var url = this.apiUrl + 'notifications/count?type=' + type
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetNotifications(startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'notifications/list?startDate=' + startDate + "&endDate=" + endDate;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public MarkAsReadEvent(id:number,type:boolean): Observable<any>{
    var url=this.apiUrl+'notifications/markAsRead?id='+id;
    var data:any={"read":type};
    return this.http.put<any>(url,data,httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public MarkAllAsRead(): Observable<any> {
    var url = this.apiUrl + 'notifications/markAllAsRead';
    var data: any = { "read": true };
    return this.http.put<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetEventsGrouped(hardwareId: string, startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'events/grouped?hardwareId=' + hardwareId + "&startDate=" + startDate + "&endDate=" + endDate
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetErrorsGrouped(hardwareId: string, startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'errors/grouped?hardwareId=' + hardwareId + "&startDate=" + startDate + "&endDate=" + endDate
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetErrorDetails(hardwareId: string, date: string): Observable<any> {
    var url = this.apiUrl + 'errors/details?hardwareId=' + hardwareId + "&date=" + date
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetErrorsLastValue(hardwareId: string, variable: string): Observable<any> {
    var url = this.apiUrl + 'errors/lastValue?hardwareId=' + hardwareId + "&variable=" + variable;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetGroupedMeasurements(hardwareId: string, startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'measurements/grouped/bydate?hardwareId=' + hardwareId + '&startDate=' + startDate + '&endDate=' + endDate;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetRawData(hardwareId: string, variable: string, startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'measurements/raw?hardwareId=' + hardwareId + '&variable=' + variable + '&startDate=' + startDate + '&endDate=' + endDate;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetInfoData(hardwareId: string, variable: string): Observable<any> {
    var url = this.apiUrl + 'measurements/info?hardwareId=' + hardwareId + '&variable=' + variable;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetAggregatedDataByDate(hardwareId: string, variable: string, startDate: string, endDate: string, method: string, resolution: string): Observable<any> {
    var url = this.apiUrl + 'measurements/aggregated/bydate?hardwareId=' + hardwareId + '&variable=' + variable + '&startDate=' + startDate + '&endDate=' + endDate + '&method=' + method + "&resolution=" + resolution;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetDashboardByView(hardwareId: string, view: string): Observable<any> {
    var url = this.apiUrl + 'dashboard/byView?hardwareId=' + hardwareId + '&view=' + view;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public PostDashboard(data: any): Observable<any> {
    var url = this.apiUrl + 'dashboard';
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public PostWidgets(data: any): Observable<any> {
    var url = this.apiUrl + 'widget';
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public DeleteWidget(id: number): Observable<any> {
    var url = this.apiUrl + 'widget?id=' + id;
    return this.http.delete<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }


  public GetAggregatedData(hardwareId: string, variable: string, startDate: string, endDate: string, method: string): Observable<any> {
    var url = this.apiUrl + 'measurements/aggregated?hardwareId=' + hardwareId + '&variable=' + variable + '&startDate=' + startDate + '&endDate=' + endDate + '&method=' + method;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetAggregatedEvents(hardwareId: string, variable: string, startDate: string, endDate: string, method: string): Observable<any> {
    var url = this.apiUrl + 'events/aggregated?hardwareId=' + hardwareId + '&variable=' + variable + '&startDate=' + startDate + '&endDate=' + endDate + '&method=' + method;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }


  public GetAggregatedDataWithCondition(hardwareId: string, variable: string, startDate: string, endDate: string, method: string, condition: string): Observable<any> {
    var url = this.apiUrl + 'measurements/aggregated?hardwareId=' + hardwareId + '&variable=' + variable + '&startDate=' + startDate + '&endDate=' + endDate + '&method=' + method + "&condition=" + condition;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetAggregatedErrorsWithCondition(hardwareId: string, variable: string, startDate: string, endDate: string, method: string, condition: string): Observable<any> {
    var url = this.apiUrl + 'errors/aggregated?hardwareId=' + hardwareId + '&variable=' + variable + '&startDate=' + startDate + '&endDate=' + endDate + '&method=' + method + "&condition=" + condition;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetAggregatedEventsWithCondition(hardwareId: string, variable: string, startDate: string, endDate: string, method: string, condition: string): Observable<any> {
    var url = this.apiUrl + 'events/aggregated?hardwareId=' + hardwareId + '&variable=' + variable + '&startDate=' + startDate + '&endDate=' + endDate + '&method=' + method + "&condition=" + condition;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetLastValue(hardwareId: string, variable: string): Observable<any> {
    var url = this.apiUrl + 'measurements/lastValue?hardwareId=' + hardwareId + '&variable=' + variable;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetPreventiveLastValue(hardwareId: string): Observable<any> {
    var url = this.apiUrl + 'preventives/lastValue?hardwareId=' + hardwareId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetPreventiveNotCheckedLastValue(hardwareId: string): Observable<any> {
    var url = this.apiUrl + 'preventives/lastNotCheckedValue?hardwareId=' + hardwareId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public PostMeasurement(hardwareId: string, data: any): Observable<any> {
    var url = this.apiUrl + 'measurements?hardwareId=' + hardwareId;
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public CheckKeyckloackUser(data: any): Observable<any> {
    var url = this.apiUrl + 'keycloak';
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public PostUserEvent(data: any): Observable<any> {
    var url = this.apiUrl + 'userEvents';
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetUserEvents(startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'userEvents/bydate?startDate=' + startDate + '&endDate=' + endDate;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }
  public GetDevicesList(): Observable<any> {
    var url = this.apiUrl + 'device/list'
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public PostKeycloak(code: string): Observable<any> {
    var url = this.apiUrl + 'user/keycloak';
    var data = { code: code };
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetKeycloakToken(code: string): Observable<any> {
    var url = this.keycloakUrl + 'token';
    var httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    var data = 'client_id='+this.keycloakClientId+'&client_secret='+this.keycloakClientSecret+'&grant_type=authorization_code&redirect_uri='+this.keycloakRedirectLogin+'&code=' + code;
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetKeycloakTokenMobile(code: string): Observable<any> {
    var url = this.keycloakUrl + 'token';
    var httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    var data = 'client_id='+this.keycloakClientId+'&client_secret='+this.keycloakClientSecret+'&grant_type=authorization_code&redirect_uri='+this.keycloakRedirectLoginMobile+'&code=' + code;
    return this.http.post<any>(url, data, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetKeycloakUserInfo(token: string): Observable<any> {
    var auth = "Bearer " + token;
    var url = this.keycloakUrl + 'userinfo';
    var httpOptions = {
      headers: new HttpHeaders({ 'Authorization': auth })
    };
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetConfigurationDataByDate(hardwareId: string, date: string): Observable<any> {
    var url = this.apiUrl + 'configuration/groupByDate?hardwareId=' + hardwareId + '&date=' + date
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetConfigurationsListByDates(hardwareId: string, startDate: string, endDate: string): Observable<any> {
    var url = this.apiUrl + 'configuration/dates?hardwareId=' + hardwareId + '&startDate=' + startDate + '&endDate=' + endDate
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetConfigurationLiveStatus(hardwareId: string): Observable<any> {
    var url = this.apiUrl + 'configuration/liveStatus?hardwareId=' + hardwareId
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetWidgetData(endpoint: string): Observable<any> {
    var url = this.apiUrl + endpoint;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }
  public LockElevator(hardwareId: string): Observable<any> {
    var url = this.apiUrl + "measurements/lock?hardwareId=" + hardwareId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public UnlockElevator(hardwareId: string): Observable<any> {
    var url = this.apiUrl + "measurements/unlock?hardwareId=" + hardwareId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }

  public GetAllMessagesData(hardwareId: string, startDate: string, endDate: string): Observable<any> {
    var errors_url = this.apiUrl + 'errors/grouped?hardwareId=' + hardwareId + "&startDate=" + startDate + "&endDate=" + endDate
    const all_errors = this.http.get<any>(errors_url, httpOptions)

    var events_url = this.apiUrl + 'events/grouped?hardwareId=' + hardwareId + "&startDate=" + startDate + "&endDate=" + endDate
    const all_events = this.http.get<any>(events_url, httpOptions)

    var preventives_url = this.apiUrl + 'preventives/list?hardwareId=' + hardwareId + "&startDate=" + startDate + "&endDate=" + endDate
    const all_preventives = this.http.get<any>(preventives_url, httpOptions)

    return forkJoin([all_errors, all_events, all_preventives]);
  }

  public GetLastLiveStatus(hardwareId: string): Observable<any> {
    var url = this.apiUrl + '/errors/liveStatus?hardwareId=' + hardwareId;
    return this.http.get<any>(url, httpOptions).pipe(
      catchError(this.handleError('getUseList'))
    );
  }
  

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure


      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
