import { NgModule } from '@angular/core';
import { ThemeModule } from '@app/@theme/theme.module';
import {ErrorDetailsDialogComponent } from './errorDetailsDialog.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
@NgModule({
  imports: [
    ThemeModule,AutocompleteLibModule
  ],
  exports: [ErrorDetailsDialogComponent],
  declarations: [
    ErrorDetailsDialogComponent
  ],
})
export class ErrorDetailsDialogModule { }
