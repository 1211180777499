import { Component, Input, OnInit,ViewChild,ElementRef } from '@angular/core';
import {DefaultEditor } from 'ng2-smart-table';
import { ApiService } from '@app/@core/data';
import { ElevatorStatusCardComponent } from '@app/components/elevator-status-card/elevator-status-card.component';
@Component({
  selector: 'app-checkbox',
  template: `
    <button class="m-1" nbButton status="danger" *ngIf="rowData.communicationCode==400" nbTooltip="{{'buttons.communication-status' | translate}}">
        <nb-icon icon="wifi-off-outline" class="icon" style="color:white;" [options]="{ animation: { type: 'pulse',infinite: true, hover:false } }"></nb-icon>
    </button>
    <button class="m-1" nbButton status="success" outline *ngIf="rowData.communicationCode==200" nbTooltip="{{'buttons.communication-status' | translate}}">
        <nb-icon icon="wifi-outline" class="icon" style="color: green;" ></nb-icon>
    </button>

  <button class="m-1" nbButton status="danger"  *ngIf="rowData.operationalCode==400 && rowData.communicationCode==200" nbTooltip="{{'buttons.operational-status' | translate}}">
    <nb-icon icon="alert-circle-outline" class="icon" style="color:white;"  [options]="{ animation: { type: 'pulse',infinite: true, hover:false } }"></nb-icon>
  </button>   
  <button class="m-1" nbButton status="warning" *ngIf="rowData.operationalCode==300 && rowData.communicationCode==200" nbTooltip="{{'buttons.operational-status' | translate}}">
    <nb-icon icon="alert-triangle-outline" class="icon" style="color:white;"  [options]="{ animation: { type: 'pulse',infinite: true, hover:false } }"></nb-icon>
  </button>   
  <button class="m-1" nbButton status="success" outline  *ngIf="rowData.operationalCode==200 && rowData.communicationCode==200" nbTooltip="{{'buttons.operational-status' | translate}}">
    <nb-icon icon="checkmark-circle-2-outline" class="icon" style="color: green;" ></nb-icon>
  </button>   
  <button class="m-1" nbButton status="basic"  *ngIf="rowData.communicationCode==400" nbTooltip="{{'buttons.operational-status' | translate}}">
    <nb-icon icon="slash-outline" class="icon" style="color:grey;"  [options]="{ animation: { type: 'pulse',infinite: true, hover:false } }"></nb-icon>
  </button>   
`
})
export class CustomTableLiveStatusComponent extends DefaultEditor implements OnInit {
 
  @Input() value: any;
  @Input() rowData: any;

  constructor(private apiService: ApiService) { 
    
    super();
  }


  ngOnInit() {
  }

}